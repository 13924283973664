import React, { useState } from 'react';

function SalesTaxCalculator() {
  const [price, setPrice] = useState('');
  const [taxRate, setTaxRate] = useState(0.08); // Default tax rate of 8%
  const [totalPrice, setTotalPrice] = useState(0);

  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };

  const handleTaxRateChange = (event) => {
    setTaxRate(parseFloat(event.target.value));
  };

  const calculateTotalPrice = () => {
    const priceFloat = parseFloat(price);
    const total = priceFloat + priceFloat * taxRate;
    setTotalPrice(total.toFixed(2)); // Round to 2 decimal places
  };

  return (
    <div>
      <h1>Sales Tax Calculator</h1>
      <div>
        <label>Price:</label>
        <input type="number" value={price} onChange={handlePriceChange} />
      </div>
      <div>
        <label>Tax Rate:</label>
        <select value={taxRate} onChange={handleTaxRateChange}>
          <option value={0.06}>6%</option>
          <option value={0.08}>8%</option>
          <option value={0.1}>10%</option>
          <option value={0.15}>15%</option>
          <option value={0.16}>16%</option>
        </select>
      </div>
      <button onClick={calculateTotalPrice}>Calculate Total Price</button>
      {totalPrice > 0 && (
        <div>
          <h2>Total Price (including tax): ${totalPrice}</h2>
        </div>
      )}
    </div>
  );
}

export default SalesTaxCalculator;
